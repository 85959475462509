<template>
  <v-list-item @click="$emit('click', thread)" :class="{ resolved: isResolved }">
    <v-avatar size="40" class="mr-3">
      <v-img :src="getAvatar(thread)" width="40" />
    </v-avatar>
    <v-list-item-content class="pa-1">
      <v-list-item-title class="name black--text">{{ thread.name }}</v-list-item-title>
      <v-list-item-subtitle><div v-html="message" /> </v-list-item-subtitle>
      <div>
        <v-chip v-if="thread.group_id" x-small color="blue lighten-3 white--text mr-2">{{ groupName }}</v-chip>
        <v-chip v-if="thread.user_id" x-small color="green lighten-3 white--text">{{ userName }}</v-chip>
      </div>
    </v-list-item-content>
    <v-list-item-action class="my-2">
      <div class="time mb-1">{{ showDateTime(thread.update_time) }}</div>
      <v-chip v-if="thread.unread_messages > 0" x-small color="blue lighten-3 white--text mt-2">
        {{ thread.unread_messages }}
      </v-chip>
      <v-chip v-if="thread.is_spam" x-small color="red darken-3 white--text">
        {{ $t("mail.spam") }}
      </v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import ChatMixin from "./ChatMixin";

export default {
  name: "ThreadRow",
  mixins: [ChatMixin],
  computed: {
    groupName() {
      if (!this.thread.group_id) return "";
      return this.$store.getters.getAccountGroup(this.thread.group_id).name;
    },
    userName() {
      if (!this.thread.user_id) return "";
      const user = this.$store.getters.getUser(this.thread.user_id);
      return user ? user.name : "";
    },
    message() {
      let text = (this.thread.last_message || "").replaceAll("\n", " ");
      // if (thread.type === "em") {
      //   thread.last_message_text = thread.last_message_text.replace(/<[^>]*>?/gm, "");
      // }
      text = text.replace("image:", "&#128206; ");
      return text;
    },
    isResolved() {
      return this.thread.conversation === null || !!this.thread.conversation.close_time;
    },
  },
  props: {
    thread: Object,
  },
};
</script>

<style scoped lang="scss">
.v-list-item {
  background: #f2f5ff;
  .v-list-item__subtitle {
    white-space: break-spaces;
    font-size: 80%;
    color: #000 !important;
  }
  .time {
    font-weight: 300;
    font-size: 0.75rem;
  }
  &.resolved {
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    .v-list-item__subtitle {
      font-weight: normal !important;
    }
  }
}
</style>
