<template>
  <v-container fluid>
    <v-card>
      <v-card-title> {{ $t("widgets.site") }} {{ item.hosts }} </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="mt-4 body text--black">{{ $t("widgets.copy-code") }} &lt;/body&gt;</div>
            <div class="code mb-4">
              &lt;script src="https://widget.supportix.co/bundle.js" async defer data-key="{{
                item.key
              }}"&gt;&lt;/script&gt;
            </div>

            <v-textarea
              v-model="item.hosts"
              dense
              outlined
              hide-details
              rows="3"
              auto-grow
              :label="$t('widgets.domains')"
            />
            <div class="caption">{{ $t("example") }}: www.example.com, *.example.net</div>
            <v-divider class="my-2" />
            <v-row>
              <v-col>
                <v-card class="mx-auto elevation-2">
                  <v-list nav>
                    <v-list-item-group v-model="selected">
                      <template v-for="(widget, i) in widgets">
                        <v-list-item
                          :key="widget.id"
                          :disabled="widget.disabled"
                          link
                          @click="editWidget(widget)"
                          class="mb-0"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{ $t("widgets.widget-" + widget.type) }}</v-list-item-title>
                            <v-list-item-subtitle>{{ getWidgetDescription(widget) }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-switch dense hide-details v-model="widget.active" class="mt-0" />
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="i" v-if="i < widgets.length - 1" />
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <iframe ref="preview" frameborder="0" height="500" width="100%" :src="previewUrl" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        <v-btn outlined color="primary" :to="{ name: 'settings-widget-groups' }" class="ml-4">{{ $t("cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="widgetForm" max-width="600">
      <ChatSettings v-if="widget && widget.type === 'chat'" v-model="widget.data" @close="closeWidgetForm" />
      <SupportButtonSettings
        v-if="widget && widget.type === 'support'"
        v-model="widget.data"
        :channels="channels"
        @close="closeWidgetForm"
      />
      <SurveySettings v-if="widget && widget.type === 'survey'" v-model="widget.data" @close="closeWidgetForm" />
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "../BaseView.vue";
import ChatSettings from "./ChatSettings.vue";
import SupportButtonSettings from "./SupportButtonSettings.vue";
import SurveySettings from "./SurveySettings.vue";

const baseURL = "/widgets/groups";

export default {
  name: "SettingsWidgets",
  components: { ChatSettings, SupportButtonSettings, SurveySettings },
  mixins: [BaseView],
  data() {
    return {
      perm: "widgets",
      channels: [],
      channelsMap: {},
      supportColor: {},
      colorPicker: null,
      item: {},
      selected: null,
      widget: { type: null },
      widgets: [],
      chat: { type: "chat", active: false, disabled: true, data: { title: "", right: "", left: "", bottom: "" } },
      support: { type: "support", active: false, disabled: false, data: {} },
      survey: { type: "survey", active: false, disabled: false, data: {} },
      widgetForm: false,
      loading: false,
      tab: 0,
    };
  },
  computed: {
    previewUrl() {
      return "/api/widgets/groups/" + this.item.key + "/preview";
    },
  },
  async created() {
    this.setTitle(this.$t("widgets.widgets"));
    this.checkPermission();

    this.widgets = [this.support, this.survey];

    await this.loadData();
  },
  methods: {
    closeWidgetForm() {
      this.widgetForm = false;
      this.reloadPreview();
    },
    getWidgetDescription(widget) {
      let description = "";
      switch (widget.type) {
        case "support": {
          const channels = widget.data && widget.data.channels ? widget.data.channels.map((c) => c.name) : [];
          description = channels.length > 0 ? channels.join(", ") : "not configured";
          break;
        }

        case "survey": {
          description = "Опрос качества обслуживания";
          break;
        }
      }
      return description;
    },
    editWidget(widget) {
      if (!widget.active) return;
      this.widget = widget;
      this.widgetForm = true;
    },
    async loadData() {
      await Promise.all([this.loadWidgetGroup(), this.loadChannels()]);
    },
    processGroup(group) {
      this.item = group;

      // assign widget settings by type
      for (const widget of this.item.widgets) {
        Object.assign(this[widget.type], widget);
        this[widget.type].disabled = false;
      }

      // enable chat settings if we have chat channel in support widget
      if (this.support.data && this.support.data.channels) {
        const chatChannel = this.support.data.channels.find((c) => c.type === "ch");
        if (chatChannel) {
          this.chat.disabled = false;
          this.chat.active = true;

          if (this.support.data.chat) {
            Object.assign(this.chat.data, this.support.data.chat);
          }

          this.widgets.push(this.chat);
        }
      }
    },
    async loadWidgetGroup() {
      if (this.$route.params.id === "new") return;
      try {
        this.loading = true;
        const resp = await this.$api.get(baseURL + "/" + this.$route.params.id);
        this.loading = false;

        this.processGroup(resp.data);
      } catch (error) {
        console.error("Error load widget group info: ", error);
      }

      this.loading = false;
    },
    async loadChannels() {
      try {
        const resp = await this.$api.get("chat/channels");

        this.channels = [];
        this.channelsMap = {};
        for (const channel of resp.data.channels) {
          this.channels.push(channel);
          this.channelsMap[channel.id] = channel;
        }
      } catch (error) {
        console.error("Error load channels list: ", error);
      }
    },
    reloadPreview() {
      this.$refs.preview.contentWindow.location.reload();
    },
    async save() {
      const group = {
        id: this.item.id,
        hosts: this.item.hosts,
        widgets: [],
      };

      for (const widget of this.widgets) {
        if (widget.disabled) continue;

        if (widget.type === "chat") continue;

        if (widget.type === "support") {
          for (const n in widget.data.channels) {
            const chId = widget.data.channels[n].id;
            widget.data.channels[n].type = this.channelsMap[chId].type;
            widget.data.channels[n].url = this.channelsMap[chId].info?.uri;

            if (widget.data.channels[n].type === "ch") {
              widget.data.chat = { ...this.chat.data };
            }
          }
        }

        group.widgets.push(widget);
      }

      const resp = await this.$api.save(baseURL, group);
      if (resp.success) {
        this.item = resp.data;
        this.showSnackbar("success", this.$t("saved"));
        this.reloadPreview();
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.code {
  border: solid 1px #ccc;
  padding: 10px;
  color: #000;
  font: 500 12px "Courier New";
  word-break: break-word !important;
}
</style>
