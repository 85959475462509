<template>
  <v-card>
    <v-card-title>
      {{ $t("widgets.survey-settings") }}
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="widget.title"
            @blur="save"
            :label="$t('widgets.survey-rate-us')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field dense outlined hide-details type="number" v-model="widget.max_rate" />
        </v-col>
        <v-col cols="6">
          <v-menu v-model="colorPicker" :close-on-content-click="false" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field
                v-model="color.hex"
                outlined
                dense
                hide-details
                :label="$t('widgets.color')"
                readonly
                :style="{ backgroundColor: color ? color.hex : '' }"
                v-on="on"
              />
            </template>
            <v-color-picker v-model="color" hide-inputs mode="rgba" />
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            hide-details
            v-model="widget.thanks_message"
            :label="$t('widgets.survey-thanks-message')"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SurveySettings",
  created() {
    Object.assign(this.widget, this.value);
    if (this.widget.color) {
      this.color = { hex: this.widget.color || "" };
    }
  },
  data() {
    return {
      color: { hex: "" },
      colorPicker: null,
      widget: {
        title: this.$t("widgets.survey-rate-us-text"),
        color: "",
        max_rate: 5,
        thanks_message: this.$t("widgets.survey-thanks-message-text"),
      },
    };
  },
  methods: {
    save() {
      this.widget.color = this.color.hex;
      this.$emit("input", this.widget);
      this.$emit("close");
    },
  },
  props: {
    value: Object,
  },
  watch: {
    value() {
      Object.assign(this.widget, this.value);
      if (this.widget.color) {
        this.color = { hex: this.widget.color || "" };
      }
    },
  },
};
</script>
